import { Button } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { CommissionSchemas } from 'types/commission';
import { DictionaryTypes } from 'types/dictionary';

export const createColumn = (
  deleteCommission: (id: number) => void,
  setEditedCommissionSchema: (id: number, isCopyAction?: boolean) => void,
  dictionaries?: DictionaryTypes
): Column<CommissionSchemas>[] => {
  return [
    {
      Header: 'Data utworzenia',
      accessor: 'createdAt',
    },
    {
      Header: 'Nazwa schematu',
      accessor: 'schemaName',
    },
    {
      Header: 'Grupa Produktowa',
      id: 'productGroupDictionaryValue',
      accessor: (row: CommissionSchemas) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.productGroup,
            dictionaries[DICTIONARY_TYPES.PRODUCT_GROUP]
          );

          if (value) return value.value;
        }
        return row.productGroup;
      },
    },
    {
      Header: 'Ostatnia modyfikacja',
      accessor: 'lastModifiedAt',
    },
    {
      Header: 'Modyfikowane przez',
      accessor: 'lastModifiedBy',
    },
    {
      Header: 'Opis',
      accessor: 'description',
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { commissionSchemaId },
        },
      }: Cell<CommissionSchemas>) => {
        const navigate = useNavigate();

        return (
          <div className="flex space-x-6 justify-end">
            <Button variant="light" onPress={() => setEditedCommissionSchema(commissionSchemaId)}>
              <i className="bi bi-three-dots-vertical cursor-pointer" />
            </Button>

            <Button
              variant="light"
              onPress={() =>
                navigate(`/commissions/commission-schemas/${commissionSchemaId}`, {
                  state: { id: commissionSchemaId },
                })
              }>
              <i className="bi bi-gear cursor-pointer"></i>
            </Button>

            <Button
              variant="light"
              onPress={() => setEditedCommissionSchema(commissionSchemaId, true)}>
              <i className="bi bi-clipboard-plus cursor-pointer" />
            </Button>

            {/* <Button variant="light" onPress={() => deleteCommission(commissionSchemaId)}>
              <i className="bi bi-trash cursor-pointer" />
            </Button> */}
          </div>
        );
      },
    },
  ];
};
