import {
  Button,
  Header,
  Icon,
  Loader,
  MultiSelectField,
  Option,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { FieldArray, Form, useFormikContext } from 'formik';
import {
  CreatePdfDocumentFormValues,
  CreatePdfDocumentFormValuesStep4,
} from 'pages/Documents/CreateDocumentsFromPdf/CreatePdfDocumentFormData/createPdfDocumentFormValidationSchema';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';

import { assignFormValuesToFormData } from 'utils/assignFormValuesToFormData';
import { saveFile } from 'utils/file';

import ErrorMessages from '../../../../../../components/ErrorMessages/ErrorMessages';
import {
  draftTemplateDocument,
  previewTemplateDocument,
} from '../../../../../../services/documents';
import {
  CreateDocumentRequestParams,
  DocumentMemberRoles,
  DocumentTemplateAssignmentReceiver,
  DocumentTemplateMember,
} from '../../../../../../types/documents';
import { DocumentSignatureStatus, UserDocumentRole } from '../../../../../../types/documents';

interface Props {
  onReset: () => void;
  onSuccess: () => void;
}

const SignatureQueueModal = ({ onReset, onSuccess }: Props) => {
  const { values, errors, setErrors, setFieldValue } =
    useFormikContext<CreatePdfDocumentFormValuesStep4>();

  useEffect(() => {
    setErrors({});
  }, [setErrors]);

  useEffect(() => {
    setFieldValue('queue', []);
  }, [values.signature]);

  const documentSignatureStatusOptions = Object.values(DocumentSignatureStatus).map((value) => ({
    value,
    key: value,
  }));

  const {
    isError: isErrorPreview,
    isLoading: isLoadingPreview,
    mutate: mutatePreview,
    error: errorPreview,
  } = useMutation<AxiosResponse, AxiosError, FormData>(
    (formData) => previewTemplateDocument(formData),
    {
      onSuccess: (response) => {
        saveFile(
          response.data,
          `${
            (values as CreatePdfDocumentFormValues).documentName
          } ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}.pdf`,
          'application/pdf'
        );
      },
    }
  );

  const {
    isError: isErrorDraft,
    isLoading: isLoadingDraft,
    mutate: mutateDraft,
    error: errorDraft,
  } = useMutation<AxiosResponse, AxiosError, FormData>(
    (formData) => draftTemplateDocument(formData),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const assignSignatureOrder = useCallback((formValues: CreatePdfDocumentFormValues) => {
    formValues.assignments.forEach((assignment) => {
      formValues.queue.forEach(({ recipients }: { recipients: string[] }, index: number) => {
        recipients.forEach((recipient) => {
          const [roleName, roleOrder] = recipient.split('-');

          const membersWithRequestedRole = assignment.members.filter(
            (member) => member.documentRole === roleName
          );

          membersWithRequestedRole[Number.parseInt(roleOrder) || 0].signatureOrder = index + 1;
          return recipient;
        });
      });
    });
  }, []);

  const generateMembersArray = useCallback((assignments: DocumentTemplateAssignmentReceiver[]) => {
    const members: DocumentTemplateMember = assignments.flatMap((assignment) =>
      assignment.members.map((member) => ({
        externalId: member.externalId,
        documentRole: member.documentRole,
      }))
    );

    const uniqueMembers: DocumentTemplateMember = members.reduce(
      (acc: DocumentTemplateMember, current) => {
        if (!acc.some((item) => item.externalId === current.externalId)) {
          acc.push(current);
        }
        return acc;
      },
      []
    );

    return uniqueMembers;
  }, []);

  const getFormDataFromValues = useCallback(
    (shouldSend: boolean) => {
      const formValues = values as CreatePdfDocumentFormValues;
      const formData = new FormData();

      assignSignatureOrder(formValues);
      const template: CreateDocumentRequestParams = {
        shouldSend,
        documentName: formValues.documentName,
        documentType: formValues.documentType,
        customContent: formValues.customContent
          ?.replaceAll('<figure', '<div')
          .replaceAll('</figure', '</div'),
        isSignatureRequired: formValues.signature === DocumentSignatureStatus.SIGNED_DOCUMENT,
        documentGenerationType: formValues.documentGenerationType,
        date: format(new Date(), 'yyyy-MM-dd') + 'T' + format(new Date(), 'HH:mm:ss'),
        assignments: formValues.assignments,
        members: generateMembersArray(formValues.assignments),
        attachmentsFromTemplates: formValues.attachmentsFromTemplates || [],
      };

      assignFormValuesToFormData<CreateDocumentRequestParams>(
        formData,
        template,
        'documentRequest'
      );

      if (formValues.attachments?.length) {
        formValues.attachments.map((value): FormData | undefined => {
          if (!value) return;
          formData.append('attachments', value.file);
          return formData;
        });
      }

      if (formValues?.documentBase?.file) {
        formData.append('documentBase', formValues.documentBase.file);
      }

      return formData;
    },
    [values]
  );

  const downloadDocument = () => {
    mutatePreview(getFormDataFromValues(false));
  };

  const sendDocument = () => {
    mutateDraft(getFormDataFromValues(true));
  };

  const isDisabled = useMemo(() => {
    if (Object.entries(errors).length) return true;

    const formValues = values as CreatePdfDocumentFormValues;
    const maxNumberOfParties = formValues.assignments.reduce((max, receiver) => {
      return Math.max(max, receiver.members?.length || 0);
    }, 0);
    const queueLength = (formValues.queue as { recipients: string[] }[]).reduce(
      (sum, { recipients }) => {
        return recipients?.length + sum;
      },
      0
    );

    if (
      values.signature === DocumentSignatureStatus.SIGNED_DOCUMENT &&
      (!values.queue?.length || maxNumberOfParties !== queueLength)
    ) {
      return true;
    }
    return false;
  }, [errors, values.queue, values.signature]);

  const queueAvailableOptions = useMemo((): Option[] => {
    const formValues = values as CreatePdfDocumentFormValues;
    let tempOptions: Option[] = [
      {
        key: UserDocumentRole.RECEIVER,
        value: DocumentMemberRoles.RECEIVER,
      },
    ];

    const maxNumberOfParties = formValues.assignments.reduce((max, receiver) => {
      const numberOfParties =
        receiver.members?.filter((member) => member.documentRole === UserDocumentRole.PARTY)
          .length || 0;
      return Math.max(max, numberOfParties);
    }, 0);

    for (let i = 0; i < maxNumberOfParties; i++) {
      tempOptions.push({
        key: `${UserDocumentRole.PARTY}-${i}`,
        value: DocumentMemberRoles.PARTY + ' ' + (i + 1),
      });
    }

    if (formValues.proxies?.length) {
      const agentOptions = [
        ...formValues.proxies
          .filter((proxy, index) => proxy.role === UserDocumentRole.AGENT)
          .map((proxy, index) => {
            return {
              key: proxy.role + '-' + index,
              value: proxy.name || '',
            };
          }),
      ];
      const confidentialClerkOptions = [
        ...formValues.proxies
          .filter((proxy, index) => proxy.role === UserDocumentRole.CONFIDENTIAL_CLERK)
          .map((proxy, index) => {
            return {
              key: proxy.role + '-' + index,
              value: proxy.name || '',
            };
          }),
      ];
      const governorOptions = [
        ...formValues.proxies
          .filter((proxy, index) => proxy.role === UserDocumentRole.GOVERNOR)
          .map((proxy, index) => {
            return {
              key: proxy.role + '-' + index,
              value: proxy.name || '',
            };
          }),
      ];
      const boardPresidentOptions = [
        ...formValues.proxies
          .filter((proxy, index) => proxy.role === UserDocumentRole.BOARD_PRESIDENT)
          .map((proxy, index) => {
            return {
              key: proxy.role + '-' + index,
              value: proxy.name || '',
            };
          }),
      ];
      const emptyOptions = [
        ...formValues.proxies
          .filter((proxy, index) => proxy.role === UserDocumentRole.EMPTY)
          .map((proxy, index) => {
            return {
              key: proxy.role + '-' + index,
              value: proxy.name || '',
            };
          }),
      ];

      tempOptions = [
        ...tempOptions,
        ...agentOptions,
        ...confidentialClerkOptions,
        ...governorOptions,
        ...boardPresidentOptions,
        ...emptyOptions,
      ];
    }

    return tempOptions;
  }, [values]);

  const signingQueueOptions = useCallback(
    (index: number): Option[] => {
      let queueOptions: Option[] = [];

      const selectedRecipients = values.queue
        ?.flatMap(({ recipients }: { recipients: string[] }) => {
          return recipients;
        })
        .filter((recipient: string) => !!recipient);

      queueOptions = queueAvailableOptions.filter((item) => {
        return !selectedRecipients.includes(item.key as string);
      });

      values.queue
        ?.find((item: string[], i: number) => i === index)
        .recipients?.forEach((recipient: string) => {
          const temp = queueAvailableOptions.find((item: Option) => item.key === recipient);
          if (temp) {
            queueOptions.push(temp);
          }
        });

      return queueOptions;
    },
    [queueAvailableOptions]
  );

  return (
    <>
      <div className="flex flex-col items-center">
        <Header as="h1" size="xl" weight="semibold">
          Utwórz dokument pdf
        </Header>
        <Text size="lg">Krok 4 - kolejka podpisu</Text>
      </div>

      <div>
        <Form>
          <div className="my-4 flex flex-col gap-8">
            <SelectField name="signature" label="Podpis" options={documentSignatureStatusOptions} />

            {values.signature === DocumentSignatureStatus.SIGNED_DOCUMENT && (
              <div>
                <FieldArray name="queue">
                  {(helpers) => (
                    <div>
                      <div className="flex justify-between">
                        <Text weight="bold" size="lg">
                          Kolejka podpisu
                        </Text>
                        <Button className="flex gap-2" size="sm" onPress={() => helpers.push({})}>
                          <Icon name="plus-lg" />
                          <Text>Dodaj kolejny poziom</Text>
                        </Button>
                      </div>

                      {(values.queue as [])?.map((query, index) => (
                        <div className="flex flex-col mt-4" key={index}>
                          <div className="flex w-full gap-4 items-center">
                            <Text size="lg">{index + 1}.</Text>
                            <div className="w-full">
                              <MultiSelectField
                                label={`Odbiorcy ${index + 1} poziomu`}
                                name={`queue[${index}].recipients`}
                                options={signingQueueOptions(index)}
                              />
                            </div>
                            <Button
                              onPress={() => {
                                helpers.remove(index);
                              }}
                              className="flex gap-2 self-end">
                              <i className="bi bi-trash cursor-pointer" />
                            </Button>
                          </div>
                          {(errors?.queue as any)?.[index] && (
                            <p className="text-xs font-normal text-red-700">
                              {((errors?.queue as any)?.[index] as any).recipients}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            )}

            <div className="flex justify-end gap-4">
              <Button
                isDisabled={isLoadingPreview || isLoadingDraft}
                className="flex space-x-2"
                onPress={onReset}
                variant="outline-primary">
                <Icon name="x-lg" />
                <Text>Anuluj</Text>
              </Button>
              <Button
                isDisabled={isLoadingPreview || isLoadingDraft || isDisabled}
                className="flex space-x-2"
                onPress={downloadDocument}
                variant="outline-primary">
                <Icon name="eye" />
                <Text>Podgląd</Text>
              </Button>
              <Button
                className="flex space-x-2"
                type="submit"
                isDisabled={isLoadingPreview || isLoadingDraft || isDisabled}
                variant="primary"
                onPress={sendDocument}>
                {isLoadingPreview ||
                  (isLoadingDraft && <Loader className="h-4 w-4 !border-white" />)}
                <Icon name="send-fill" />
                <Text>Wyślij</Text>
              </Button>
            </div>
            {isErrorPreview ||
              (isErrorDraft && <ErrorMessages error={errorPreview || errorDraft} />)}
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignatureQueueModal;
