import {
  AutoCompleteField,
  Button,
  Header,
  Icon,
  InputField,
  Option,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import InsetLoader from 'components/InsetLoader/InsetLoader';
import { USER } from 'constants/queries/user';
import { getUsersByRole } from 'services/user';
import { Roles, User } from 'types/user';

import { ProxyRoles, UserDocumentRole } from '../../../../../../types/documents';
import { CreatePdfDocumentFormValuesStep1 } from '../../../CreatePdfDocumentFormData/createPdfDocumentFormValidationSchema';

interface Props {
  onReset: () => void;
}

const InitialDataModal = ({ onReset }: Props) => {
  const [queryUser, setQueryUser] = useState<string>('');
  const [initialUserList, setInitialUserList] = useState<User[]>([]);
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreatePdfDocumentFormValuesStep1>();
  const [debouncedQueryParam] = useDebounce(queryUser, 500);

  const proxyRolesOptions = Object.entries(ProxyRoles).map((value) => ({
    key: value[0],
    value: value[1],
  }));

  const { data: userListData, isLoading: isLoadingUserList } = useQuery<User[], AxiosError>(
    [USER.USERS_BY_ROLE],
    () => getUsersByRole(Roles.DOCUMENTS_MANAGEMENT_SEND)
  );

  useEffect(() => {
    if (!initialUserList.length) {
      setInitialUserList(userListData || []);
    }
  }, [userListData]);

  const userOptions = useCallback(
    (index: number): Option[] => {
      if (!userListData) return [];
      const selectedProxies = values.proxies?.filter((proxy) => !!proxy);

      const availableOptions = userListData
        .filter(
          (user) =>
            user.firstName?.toLowerCase()?.includes(debouncedQueryParam?.toLowerCase()) ||
            user.lastName?.toLowerCase()?.includes(debouncedQueryParam?.toLowerCase()) ||
            user.agentCode?.toLowerCase()?.includes(debouncedQueryParam?.toLowerCase()) ||
            user.email?.toLowerCase()?.includes(debouncedQueryParam?.toLowerCase())
        )
        .map((user) => ({
          key: user.keycloakId,
          value: user.firstName + ' ' + user.lastName,
        }));

      if (!selectedProxies) return availableOptions;

      return availableOptions.filter(
        (item) =>
          !selectedProxies.find((proxy) => proxy.id === item.key) ||
          selectedProxies[index]?.id === item.key
      );
    },
    [userListData, values.proxies, debouncedQueryParam]
  );

  function handleQueryUserChange(value: string, index: number) {
    setQueryUser(value);
    setFieldValue(`proxies[${index}].id`, '');
  }

  function onSubmit() {
    const proxies = values.proxies?.map((proxy) => {
      const userData = initialUserList.find((option) => option.keycloakId === proxy.id);
      return {
        ...proxy,
        name: userData?.firstName + ' ' + userData?.lastName,
      };
    });

    setFieldValue('proxies', proxies);
    setFieldValue('documentName', values.documentName.trim());
  }

  function onFileInputChange(form: any, event: any) {
    const file = event.target.files[0];
    form.setFieldValue(`documentBase.file`, file);
  }

  return (
    <div className="relative">
      {isLoadingUserList && <InsetLoader />}

      <div className="flex flex-col items-center">
        <Header as="h1" size="xl" weight="semibold">
          Utwórz dokument pdf
        </Header>
        <Text size="lg">Krok 1 - dane podstawowe</Text>
      </div>

      <div>
        <Form>
          <div className="my-4 flex flex-col gap-2">
            <InputField label="Nazwa dokumentu" name="documentName" />
            <Text size="sm" className="text-slate-500 text-center">
              Przy wysyłce na końcu nazwy każdego dokumentu zostanie dodany numer PR odbiorcy.
            </Text>

            <Field name="documentBase">
              {({ form }: any) => (
                <>
                  <input
                    accept=".pdf"
                    type="file"
                    className="w-full mt-4"
                    name="documentBase"
                    onChange={(event) => onFileInputChange(form, event)}
                  />
                  {errors?.documentBase && (
                    <p className="text-xs font-normal text-red-700">
                      {(errors?.documentBase as any).file}
                    </p>
                  )}
                </>
              )}
            </Field>

            <FieldArray name="proxies">
              {(helpers) => (
                <div>
                  <div className="my-8 flex justify-between">
                    <Text weight="bold" size="lg">
                      Pełnomocnicy
                    </Text>
                    <Button
                      className="flex gap-2"
                      size="sm"
                      onPress={() =>
                        helpers.push({
                          role: UserDocumentRole.AGENT,
                        })
                      }>
                      <Icon name="plus-lg" />
                      <Text>Dodaj kolejnego pełnomocnika</Text>
                    </Button>
                  </div>
                  <div>
                    {values.proxies?.map((proxy: any, index: any) => (
                      <div className="flex flex-col gap-4 my-8" key={index}>
                        <AutoCompleteField
                          label="Pełnomocnik"
                          name={`proxies[${index}].id`}
                          options={userOptions(index)}
                          placeholder="Pełnomocnik"
                          query={queryUser}
                          setQuery={(value) => handleQueryUserChange(value, index)}
                          className={`${
                            touched.proxies && (errors?.proxies?.[index] as any)?.id
                              ? 'border border-red-700'
                              : ''
                          }`}
                        />
                        <div className="flex w-full gap-2">
                          <div className="w-full">
                            <SelectField
                              options={proxyRolesOptions}
                              name={`proxies[${index}].role`}
                              label="Rola"
                              className={`${
                                touched.proxies && (errors?.proxies?.[index] as any)?.role
                                  ? 'border border-red-700'
                                  : ''
                              }`}
                            />
                          </div>
                          <Button
                            onPress={() => helpers.remove(index)}
                            className="flex gap-2 self-end">
                            <i className="bi bi-trash cursor-pointer" />
                          </Button>
                        </div>
                        {touched.proxies && errors?.proxies?.[index] && (
                          <p className="text-xs font-normal text-red-700">
                            {(errors?.proxies?.[index] as any).name}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </FieldArray>
          </div>

          <div className="flex justify-end gap-4">
            <Button className="flex space-x-2" onPress={onReset} variant="outline-primary">
              <Icon name="x-lg" />
              <Text>Anuluj</Text>
            </Button>
            <Button
              className="flex space-x-2"
              type="submit"
              variant="primary"
              onPress={onSubmit}
              isDisabled={!!Object.entries(errors).length}>
              <Icon name="arrow-right" />
              <Text>Dalej</Text>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InitialDataModal;
