import * as Yup from 'yup';

import {
  duplicateFileMessage,
  emptyFileInputMessage,
  requiredFieldMessage,
  requiredMultipleFieldsMessage,
} from '../../../../constants/requiredFieldMessage';
import {
  DocumentGenerationType,
  DocumentSignatureStatus,
  DocumentTemplateAssignmentReceiver,
  DocumentTemplateMember,
  RecipientType,
  UserDocumentRole,
} from '../../../../types/documents';

export type CreatePdfDocumentFormValues = CreatePdfDocumentFormValuesStep1 &
  CreatePdfDocumentFormValuesStep2 &
  CreatePdfDocumentFormValuesStep3 &
  CreatePdfDocumentFormValuesStep4 &
  CreatePdfDocumentAdditionalData;

export type CreatePdfDocumentFormValuesStep1 = Yup.InferType<
  typeof createPdfDocumentFormValidationSchemaStep1
>;
export type CreatePdfDocumentFormValuesStep2 = Yup.InferType<
  typeof createPdfDocumentFormValidationSchemaStep2
>;
export type CreatePdfDocumentFormValuesStep3 = Yup.InferType<
  typeof createPdfDocumentFormValidationSchemaStep3
>;
export type CreatePdfDocumentFormValuesStep4 = Yup.InferType<
  typeof createPdfDocumentFormValidationSchemaStep4
>;

const UserGroup = (data: RecipientType): boolean => {
  return RecipientType.MULTIPLE_RECIPIENTS === data;
};

const SingleUser = (data: RecipientType): boolean => {
  return RecipientType.SINGLE_RECIPIENT === data;
};

const File = (data: RecipientType): boolean => {
  return RecipientType.CSV_FILE === data;
};

export interface CreatePdfDocumentAdditionalData {
  members: DocumentTemplateMember[];
  assignments: DocumentTemplateAssignmentReceiver[];
  customContent: string;
  documentGenerationType: DocumentGenerationType;
  attachmentsFromTemplates: string[];
  documentType: string;
}

export const createPdfDocumentFormValidationSchemaStep1 = Yup.object({
  documentName: Yup.string().required(requiredFieldMessage),
  documentBase: Yup.object({
    file: Yup.mixed().required(requiredFieldMessage),
    uploadedFileId: Yup.number(),
  })
    .nullable()
    .required(emptyFileInputMessage),
  proxies: Yup.array().of(
    Yup.object({
      name: Yup.string(),
      id: Yup.string().required(requiredMultipleFieldsMessage),
      role: Yup.mixed<UserDocumentRole>()
        .oneOf(Object.values(UserDocumentRole))
        .required(requiredFieldMessage),
    })
  ),
});
export const createPdfDocumentFormValidationSchemaStep2 = Yup.object({
  recipientContactMethod: Yup.mixed<RecipientType>()
    .oneOf(Object.values(RecipientType))
    .required(requiredFieldMessage),
  user: Yup.mixed().when('recipientContactMethod', {
    is: SingleUser,
    then: Yup.string().required(requiredFieldMessage),
  }),
  party: Yup.mixed().when('recipientContactMethod', {
    is: SingleUser,
    then: Yup.string(),
  }),
  schema: Yup.mixed().when('recipientContactMethod', {
    is: UserGroup,
    then: Yup.string().required(requiredFieldMessage),
  }),
  positions: Yup.mixed().when('recipientContactMethod', {
    is: UserGroup,
    then: Yup.array().of(Yup.string()).required(requiredFieldMessage),
  }),
  csvFile: Yup.mixed().when('recipientContactMethod', {
    is: File,
    then: Yup.mixed().required(requiredFieldMessage),
  }),
});
export const createPdfDocumentFormValidationSchemaStep3 = Yup.object({
  attachments: Yup.array().of(
    Yup.object({
      file: Yup.mixed(),
      uploadedFileId: Yup.number(),
    })
      .nullable()
      .required(emptyFileInputMessage)
      .test('unique-file-name', duplicateFileMessage, function (value, context) {
        const formValues = (context.options as any).from?.[(context.options as any).from.length - 1]
          .value;

        if (!formValues) return true;

        const { attachments } = formValues;

        if (!attachments) return true;

        const duplicateInAttachments = attachments.filter(
          (attachment: any) => attachment.file?.name === value?.file?.name
        );

        return duplicateInAttachments.length <= 1;
      })
  ),
});

export const createPdfDocumentFormValidationSchemaStep4 = Yup.object({
  signature: Yup.mixed<DocumentSignatureStatus>()
    .oneOf(Object.values(DocumentSignatureStatus))
    .required(requiredFieldMessage),
  queue: Yup.mixed().when('signature', {
    is: (value: DocumentSignatureStatus) => value === DocumentSignatureStatus.SIGNED_DOCUMENT,
    then: Yup.array().of(
      Yup.object({
        recipients: Yup.array().of(Yup.string()).required(requiredFieldMessage),
      }).nullable()
    ),
  }),
});
