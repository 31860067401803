import { format } from 'date-fns';
import React from 'react';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { DictionaryTypes } from 'types/dictionary';
import { DocumentHistory } from 'types/documents';

interface Props {
  dictionaries?: DictionaryTypes;
}

export const createColumn = ({ dictionaries }: Props): Column<DocumentHistory>[] => {
  return [
    {
      Header: 'Akcja',
      accessor: (row: DocumentHistory) => {
        if (dictionaries && row.action) {
          const value = findDictionaryByKey(
            row.action,
            dictionaries[DICTIONARY_TYPES.DOCUMENT_HISTORY_ACTION]
          );

          if (value) return value.value;
        }
        return row.action;
      },
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: (row: DocumentHistory) => {
        if (dictionaries && row.status) {
          const value = findDictionaryByKey(
            row.status,
            dictionaries[DICTIONARY_TYPES.DOCUMENT_STATUS]
          );

          if (value) return value.value;
        }
        return row.status;
      },
      disableSortBy: true,
    },
    {
      accessor: 'sender',
      Header: 'Nadawca',
      disableSortBy: true,
    },
    {
      accessor: 'receiver',
      Header: 'Odbiorca',
      disableSortBy: true,
    },
    {
      accessor: 'actionTime',
      Header: 'Data i godzina',
      Cell: ({
        row: {
          original: { actionTime },
        },
      }: Cell<DocumentHistory>) => {
        return !!actionTime ? format(new Date(actionTime), 'dd-MM-yyyy HH:mm:ss') : '';
      },
    },
  ];
};
