import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { updateLastLoginDate } from 'services/user';
import { Roles } from 'types/user';

const keycloakConfig: KeycloakConfig = {
  url: getAuthUrl(),
  realm: process.env.REACT_APP_REALM_NAME || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
};

const _kc = Keycloak(keycloakConfig);

function initKeycloak(onAuthenticatedCallback: () => void): void {
  _kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      enableLogging: true,
    })
    .then(async (authenticated: boolean) => {
      if (authenticated) {
        return await fetch(`${window.location.origin}/recordmod/keycloak/users/me`, {
          headers: {
            Authorization: `Bearer ${_kc.token}`,
          },
        }).then((res: Response) => {
          res.json().then(async (data) => {
            if (!!(data?.roles?.includes(Roles.CONTRACTOR) && data?.roles?.length === 1)) {
              window.location.href = `${window.location.protocol}//${window.location.host}/portal-agenta`;
            } else {
              await updateLastLoginDate().then(() => {
                onAuthenticatedCallback();
              });
            }
          });
        });
      }
    })
    .catch(console.error);
}

function doLogin(): void {
  _kc.login();
}

function doLogout(): void {
  _kc.logout({ redirectUri: window.location.origin });
}

function getToken(): string | undefined {
  return _kc.token;
}

function isLoggedIn(): boolean {
  return !!_kc.token;
}

function getUsername(): string {
  return _kc.tokenParsed?.preferred_username;
}

function refreshToken() {
  return _kc.updateToken(300).catch(doLogin);
}

function getAuthUrl() {
  const SUFFIX = 'keycloak';

  return process.env.NODE_ENV === 'development'
    ? `https://proseccotst.profitowi.pl/${SUFFIX}/`
    : `${window.location.origin}/${SUFFIX}/`;
}

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  refreshToken,
  getUsername,
};

export default AuthService;
