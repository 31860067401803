import {
  Button,
  DatePickerField,
  InputField,
  MultiSelectField,
  Option,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, useFormikContext } from 'formik';
import React, { ReactNode, useMemo } from 'react';
import { useQuery } from 'react-query';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { DOCUMENTS_LIST } from 'constants/queries/documentsList';
import { USER } from 'constants/queries/user';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getTemplateCreatorData } from 'services/templateCreator';
import { getAuthors } from 'services/user';
import { SigningStatus } from 'types/documents';

import { DocumentFiltersFormValues } from './validationSchema';

function DocumentFiltersForm({
  customActionButtons,
  customClasses,
}: {
  customActionButtons?: ReactNode;
  customClasses?: string;
}) {
  const { activeDictionaries } = useDictionaryContext();
  const { perPage, currentPage: page } = usePagination(9999);

  const { errors } = useFormikContext<DocumentFiltersFormValues>();

  const { data: authorsList } = useQuery<string[], AxiosError>(
    [USER.AUTHORS, page, perPage],
    () => getAuthors(page, perPage),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const documentSigningStatusOptions = useMemo(() => {
    return !!activeDictionaries
      ? activeDictionaries?.[DICTIONARY_TYPES.SIGNING_STATUS]?.filter(
          (item) => item.key !== SigningStatus.READ
        )
      : [];
  }, [activeDictionaries, DICTIONARY_TYPES]);

  const authorOptions = useMemo(() => {
    return authorsList?.length
      ? authorsList.map((user) => ({
          key: user,
          value: user,
        }))
      : [];
  }, [authorsList]);

  const availableDocumentTypesData = useMemo(() => {
    const dictionaryOptions = !!activeDictionaries
      ? [
          ...(activeDictionaries?.[DICTIONARY_TYPES.DOCUMENT_TYPE] || []),
          ...(activeDictionaries?.[DICTIONARY_TYPES.SPECIAL_DOCUMENT_TYPE] || []),
        ]
      : [];

    return dictionaryOptions?.map((option) => ({
      key: option.key,
      value: option.value,
    }));
  }, [activeDictionaries, DICTIONARY_TYPES]);

  const {
    data: availableTemplatesData,
    isLoading: availableTemplatesIsLoading,
    isFetching: availableTemplatesIsFetching,
  } = useQuery(
    [DOCUMENTS_LIST.AVAILABLE_TEMPLATES_CREATOR],
    () => getTemplateCreatorData({ archived: false }, 0, 9999),
    {
      select: (data): Option[] =>
        data?.content?.map((template) => ({
          key: template.id,
          value: template.name,
        })),
    }
  );

  return (
    <>
      <Form className={`max-w-full ${customClasses}`}>
        <div className="my-4 flex flex-col gap-2">
          <div className="flex flex-col gap-4">
            <Text weight="bold">Odbiorca</Text>
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <InputField label="Kod" name="agentCode" />
              </div>
              <div className="w-full">
                <InputField label="Imię i nazwisko" name="recipientName" />
              </div>
            </div>
          </div>
          <InputField label="Nazwa dokumentu" name="documentName" />

          <MultiSelectField
            name="templateId"
            label="Szablon"
            options={availableTemplatesData || []}
          />

          <MultiSelectField
            name="documentTypeFromDict"
            label="Rodzaj dokumentu"
            options={availableDocumentTypesData || []}
          />

          <div className="flex gap-4 w-full">
            <div className="w-full">
              <DatePickerField
                dateFormat={'dd-MM-yyyy'}
                label="Data utworzenia od"
                name="dateFrom"
              />
            </div>
            <div className="w-full">
              <DatePickerField dateFormat={'dd-MM-yyyy'} label="Data utworzenia do" name="dateTo" />
            </div>
          </div>

          <MultiSelectField
            label="Utworzono przez"
            name="createdBy"
            options={authorOptions || []}
          />

          <MultiSelectField
            name="documentStatus"
            label="Status dokumentu"
            options={
              activeDictionaries ? activeDictionaries?.[DICTIONARY_TYPES.DOCUMENT_STATUS] : []
            }
          />

          <MultiSelectField
            name="mySigningStatus"
            label="Mój status"
            options={documentSigningStatusOptions}
          />

          {customActionButtons ? (
            customActionButtons
          ) : (
            <div className="flex gap-4">
              <Button
                size="sm"
                className="flex space-x-2"
                type="submit"
                variant="outline-primary"
                isDisabled={
                  !!Object.entries(errors).length ||
                  availableTemplatesIsLoading ||
                  availableTemplatesIsFetching
                }>
                <Text>Szukaj</Text>
              </Button>

              <Button size="sm" className="flex space-x-2" type="reset" variant="outline-primary">
                <Text>Wyczyść filtry</Text>
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
}

export default DocumentFiltersForm;
