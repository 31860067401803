import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { NavigateFunction } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { AdvancePayment } from 'types/advancePayment';
import { Bonus } from 'types/bonus';
import { DictionaryTypes } from 'types/dictionary';
import { formatBackendDateWithoutTime } from 'utils/date';

export const createColumn = (
  agentId: number,
  navigate: NavigateFunction,
  dictionaries: DictionaryTypes,
  deleteAgentBonus: (agentBonusId: number) => void
): Column<Bonus>[] => {
  return [
    {
      Header: 'Typ premii',
      id: 'bonusType',
      accessor: (row: Bonus) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.bonusType,
            dictionaries[DICTIONARY_TYPES.BONUS_TYPE]
          );

          if (value) return value.value;
        }
        return row.bonusType;
      },
    },
    {
      Header: 'Tytuł nadania premi',
      accessor: 'title',
    },
    {
      Header: 'Kwota netto',
      accessor: (row) => <div className="text-right">{row?.netValue}</div>,
    },
    {
      Header: 'Stawka VAT',
      id: 'taxPercent.value',
      accessor: (row: Bonus) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.taxPercent,
            dictionaries[DICTIONARY_TYPES.VAT_RATE]
          );

          if (value) return value.value;
        }
        return row.taxPercent;
      },
    },
    {
      Header: 'Kwota brutto',
      accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
    },
    {
      Header: 'Liczba miesięcy',
      accessor: 'paymentsCount',
    },
    {
      Header: 'Data rozpoczęcia naliczenia',
      accessor: 'paymentStartDate',
      Cell: ({
        row: {
          original: { paymentStartDate },
        },
      }: Cell<AdvancePayment>) =>
        paymentStartDate ? formatBackendDateWithoutTime(paymentStartDate) : '',
    },
    {
      Header: 'Dzień miesiąca naliczenia',
      accessor: 'paymentDayOfMonth',
    },
    {
      Header: 'Grupa produktowa',
      accessor: 'productGroupName',
    },
    {
      Header: 'Nazwa pozycji na dokumencie',
      id: 'documentPositionName.value',
      accessor: (row: Bonus) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.documentPositionName,
            dictionaries[DICTIONARY_TYPES.DOCUMENT_POSITION_NAME]
          );

          if (value) return value.value;
        }
        return row.documentPositionName;
      },
    },
    {
      Header: 'Status',
      accessor: (row: Bonus) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.status,
            dictionaries[DICTIONARY_TYPES.BONUS_STATUS]
          );

          if (value) return value.value;
        }
        return row.status;
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { bonusId },
        },
      }: Cell<Bonus>) => (
        <div className="flex space-x-6 justify-end">
          <Button
            variant="light"
            onPress={() => navigate(`/records/agent/${agentId}/bonuses/${bonusId}`)}>
            <i className="bi bi-gear cursor-pointer" />
          </Button>

          <Button variant="light" onPress={() => deleteAgentBonus(bonusId)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      ),
    },
  ];
};
