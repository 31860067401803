import { InputField, Modal } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { emailFormatError, phoneNumberFormatError } from 'constants/requiredFieldMessage';
import { EditUserPayload, User } from 'types/user';

const validationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  phoneNumber: Yup.string().matches(/^[0-9]{9,11}$/, phoneNumberFormatError),
  email: Yup.string().email(emailFormatError),
});

type Props = {
  editedItem: User;
  visible: boolean;
  editUserError?: AxiosError | null;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (payload: EditUserPayload) => void;
  closeModal: () => void;
};

const EditUserForm = ({
  editedItem,
  visible,
  editUserError,
  setVisible,
  onSave,
  closeModal,
}: Props) => {
  return (
    <Modal title={'Edytuj dane'} visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{
          firstName: editedItem.firstName,
          lastName: editedItem.lastName,
          email: editedItem.email,
          phoneNumber: editedItem.phoneNumber,
        }}
        onSubmit={onSave}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className="space-y-4">
            <InputField name="firstName" label="Imię" />
            <InputField name="lastName" label="Nazwisko" />
            <InputField name="email" label="E-mail" />
            <InputField name="phoneNumber" label="Numer telefonu" />

            <ModalActions disableSubmit={!!Object.entries(errors).length} onCancel={closeModal} />

            {editUserError && <ErrorMessages error={editUserError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserForm;
