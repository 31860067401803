import {
  AutoCompleteField,
  Button,
  CheckboxGroup,
  CheckboxGroupField,
  InputField,
  Modal,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import {
  emailFormatError,
  phoneNumberFormatError,
  requiredFieldMessage,
} from 'constants/requiredFieldMessage';
import { createAgentsOptions } from 'helpers/agents';
import { useAgentQuery } from 'hooks/useAgentQuery';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { AddUserPayload, UserType } from 'types/user';

import UsernameField from './UsernameField';

const validationSchema = Yup.object().shape({
  agentCode: Yup.string(),
  username: Yup.string().required(requiredFieldMessage),
  firstName: Yup.string(),
  lastName: Yup.string(),
  phoneNumber: Yup.string().matches(/^[0-9]{9,11}$/, phoneNumberFormatError),
  email: Yup.string().email(emailFormatError),
  password: Yup.string().min(6, 'Hasło powinno zawierać co najmniej 6 znaków'),
});

type Props = {
  visible: boolean;
  addUserError?: AxiosError | null;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (payload: AddUserPayload) => void;
  closeModal: () => void;
  userType?: UserType;
};

const AddUserForm = ({
  visible,
  setVisible,
  onSave,
  closeModal,
  userType,
  addUserError,
}: Props) => {
  const [displayAutocomplete, setDisplayAutocomplete] = useState(false);
  const [currentAgentCode, setCurrentAgentCode] = useState('');
  const [referredQueryAgent, setReferredQueryAgent] = useState('');
  const { activeDictionaries } = useDictionaryContext();

  useEffect(() => {
    if (userType === UserType.PORTAL_USER) setDisplayAutocomplete(true);
  }, []);

  const { data: searchAgents } = useAgentQuery({
    queryParam: referredQueryAgent,
    minQueryLength: 3,
  });

  const initialSearchOptions = useMemo(() => {
    return [{ key: 0, value: '' }];
  }, []);

  const referredAgentsOption = useMemo(
    () => (searchAgents ? createAgentsOptions(searchAgents.content) : initialSearchOptions),
    [searchAgents, initialSearchOptions]
  );

  const onAgentCodeChanged = (formModel: AddUserPayload) => {
    if (formModel.agentCode !== currentAgentCode) {
      setCurrentAgentCode(formModel.agentCode || '');
    }
  };

  const onAgentCodeClear = (setFieldValue: (field: string, value: any) => void) => {
    setDisplayAutocomplete(false);
    setCurrentAgentCode('');
    setReferredQueryAgent('');
    setFieldValue('agentCode', '');
    setDisplayAutocomplete(true);
  };

  return (
    <Modal title={'Dodaj użytkownika'} visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{
          agentCode: '',
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
          roles: [],
        }}
        validate={onAgentCodeChanged}
        onSubmit={onSave}
        validationSchema={validationSchema}>
        {({ setFieldValue }) => (
          <Form className="space-y-4">
            {displayAutocomplete && (
              <div className="flex space-x-4 items-stretch ">
                <div className="w-1/2">
                  <AutoCompleteField
                    label="Numer PR"
                    name="agentCode"
                    options={referredAgentsOption}
                    query={referredQueryAgent}
                    setQuery={setReferredQueryAgent}
                  />
                </div>

                <Button
                  variant="secondary"
                  className="px-4"
                  onPress={() => onAgentCodeClear(setFieldValue)}>
                  <Text>
                    <i className="bi bi-trash"></i>
                  </Text>
                </Button>
              </div>
            )}

            <UsernameField
              referredAgentsOption={referredAgentsOption}
              currentAgentCode={currentAgentCode}
              setFieldValue={setFieldValue}
            />
            <InputField name="firstName" label="Imię" />
            <InputField name="lastName" label="Nazwisko" />
            <InputField name="email" label="E-mail" />
            <InputField name="phoneNumber" label="Numer telefonu" />
            <InputField type="password" name="password" label="Hasło" autoComplete="off" />

            {userType === UserType.COMMISSION_USER && (
              <CheckboxGroupField orientation="vertical" name="roles" label="Role">
                {activeDictionaries?.[DICTIONARY_TYPES.KEYCLOAK_ROLES]
                  .filter((role) => role.key !== 'CONTRACTOR')
                  ?.map((item, index) => (
                    <CheckboxGroup.Checkbox key={index} value={item.key}>
                      {item.value}
                    </CheckboxGroup.Checkbox>
                  ))}
              </CheckboxGroupField>
            )}

            <ModalActions onCancel={closeModal} />
            {addUserError && <ErrorMessages error={addUserError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserForm;
