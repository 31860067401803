import { Alert, SelectField } from '@profitowi/component-library';
import clsx from 'clsx';
import { differenceInMonths } from 'date-fns';
import { Form, getIn, useFormikContext } from 'formik';
import DocumentFiltersForm from 'pages/Documents/DocumentsList/components/DocumentFiltersForm/DocumentFiltersForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryOptions from 'hooks/useDictionaryOptions';
import { ReportType } from 'types/reports';

import useReportConfigQuery from '../../hooks/useReportConfigQuery';
import DynamicField from '../DynamicField/DynamicField';

type Props = {
  reportType?: ReportType;
  isSelectReportTypeDisabled: boolean;
  isLoading?: boolean;
  setReportType: (reportType: ReportType) => void;
};

const ReportForm = ({
  reportType,
  isLoading: isCreateReportLoading,
  isSelectReportTypeDisabled = true,
  setReportType,
}: Props) => {
  const [customError, setCustomError] = useState<string>();
  const reportTypeOptions = useDictionaryOptions(DICTIONARY_TYPES.REPORT_TYPE);
  const { values } = useFormikContext<{ reportType: string }>();
  const navigate = useNavigate();

  const reportTypeValue = getIn(values, 'reportType');

  useEffect(() => setReportType(reportTypeValue), [reportTypeValue, setReportType]);

  const { reportConfig, isLoading, isError, isFetching, isSuccess, error } = useReportConfigQuery({
    reportType,
  });

  useEffect(() => {
    setCustomError(undefined);
    if (reportConfig && reportConfig[0].reportType === 'BILLING_ITEMS') {
      const { DATE_FROM, DATE_TO }: { DATE_FROM: Date; DATE_TO: Date } = values as any;
      const monthsDifference = differenceInMonths(DATE_TO, DATE_FROM);
      if (!DATE_TO || !DATE_FROM) {
        return;
      }
      if (DATE_TO < DATE_FROM) {
        return setCustomError('Data końcowa nie może być mniejsza od daty początkowej!');
      }
      if (monthsDifference > 2) {
        return setCustomError('Proszę wybrać zakres nie przekraczający okresu trzech miesięcy.');
      }
    }
    if (reportConfig && reportConfig[0].reportType === 'ACCOUNTING_DOCUMENTS') {
      const { DATE_FROM, DATE_TO }: { DATE_FROM: Date; DATE_TO: Date } = values as any;
      if (!DATE_TO || !DATE_FROM) {
        return;
      }
      if (DATE_TO < DATE_FROM) {
        return setCustomError('Data końcowa nie może być mniejsza od daty początkowej!');
      }
    }
  }, [values, reportConfig]);

  return (
    <Form className="space-y-4 max-w-full w-full">
      <SelectField
        className={clsx({ 'pointer-events-none': isSelectReportTypeDisabled })}
        label="Typ raportu"
        name="reportType"
        options={reportTypeOptions}
      />

      {isSuccess &&
        reportConfig &&
        (values.reportType === ReportType.DOCUMENTS ? (
          <>
            <DocumentFiltersForm customClasses="w-full" />
          </>
        ) : (
          reportConfig[0].params?.map((param) => <DynamicField configParams={param} />)
        ))}

      <ModalActions
        isLoading={isLoading || isFetching || isCreateReportLoading}
        disableSubmit={!!customError || !!error}
        onCancel={() => navigate('..')}
      />

      {isError && <ErrorMessages error={error} />}
      {customError && (
        <Alert className="my-2" type="error">
          {customError}
        </Alert>
      )}
    </Form>
  );
};

export default ReportForm;
