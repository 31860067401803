import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Formik } from 'formik';
import DownloadReportModal from 'pages/Reports/components/DownloadReportModal/DownloadReportModal';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { generateReport } from 'services/reports';
import { GenerateReportPayload, ReportType, Reports } from 'types/reports';

import DocumentFiltersForm from '../DocumentFiltersForm/DocumentFiltersForm';
import { documentFiltersFormInitialValues } from '../DocumentFiltersForm/initialValues';
import {
  DocumentFiltersFormValues,
  documentFiltersFormValidationSchema,
} from '../DocumentFiltersForm/validationSchema';

type Props = {
  refetch: () => void;
};

type Payload = {
  reportType?: ReportType;
  payload: GenerateReportPayload[];
};

const GenerateReportDocumentsModal = ({ refetch }: Props) => {
  const [report, setReport] = useState<Reports>();
  const navigate = useNavigate();

  const { error, isError, mutate, isLoading } = useMutation<
    AxiosResponse<Reports>,
    AxiosError,
    Payload
  >(({ reportType, payload }) => generateReport(payload, reportType), {
    onSuccess: ({ data }) => {
      setReport(data);
      refetch();
    },
  });

  function onSubmit(values: DocumentFiltersFormValues) {
    const paramPayload: GenerateReportPayload[] = [];

    const mappedValues: GenerateReportPayload[] = [
      { paramKey: 'AGENT_CODE', value: values?.agentCode },
      { paramKey: 'RECIPIENT_NAME', value: values?.recipientName },
      { paramKey: 'DOCUMENT_NAME', value: values?.documentName },
      { paramKey: 'TEMPLATE_ID', value: values?.templateId },
      { paramKey: 'DOCUMENT_TYPE', value: values?.documentTypeFromDict },
      {
        paramKey: 'DATE_FROM',
        value: !!values?.dateFrom ? format(new Date(values?.dateFrom), 'dd-MM-yyyy') : undefined,
      },
      {
        paramKey: 'DATE_TO',
        value: !!values?.dateTo ? format(new Date(values?.dateTo), 'dd-MM-yyyy') : undefined,
      },
      { paramKey: 'DOCUMENT_STATUS', value: values?.documentStatus },
      { paramKey: 'MY_SIGNING_STATUS', value: values?.mySigningStatus },
      { paramKey: 'CREATED_BY', value: values?.createdBy },
    ];

    mappedValues.map((param) => {
      if (Array.isArray(param.value)) {
        const paramArray = param.value as string[];

        if (paramArray.length) {
          paramArray.map((item) => paramPayload.push({ paramKey: param.paramKey, value: item }));
        } else paramPayload.push({ paramKey: param.paramKey, value: '' });
      } else
        paramPayload.push({
          paramKey: param.paramKey,
          value: param.value?.toString() as string,
        });
    });

    mutate({ payload: paramPayload, reportType: ReportType.DOCUMENTS });
  }

  return (
    <Modal title="Generowanie raportu" visible className="text-center !space-y-8">
      <Formik
        initialValues={documentFiltersFormInitialValues}
        validationSchema={documentFiltersFormValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}>
        {({}) => (
          <div className="text-left">
            <DocumentFiltersForm
              customClasses="w-full"
              customActionButtons={
                <ModalActions
                  confirmLabel="Wygeneruj raport"
                  isLoading={isLoading}
                  onCancel={() => navigate('..')}
                />
              }
            />
          </div>
        )}
      </Formik>

      {report && <DownloadReportModal report={report} setReport={setReport} />}

      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default GenerateReportDocumentsModal;
