import {
  Button,
  InputField,
  Loader,
  Option,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import React, { useState } from 'react';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import CKEditorField from '../CKEditorField/CKEditorField';
import { EditTemplateFormValues, NewTemplateFormValues } from '../EditTemplateForm';

interface Props {
  handleCancel: () => void;
  allowContentEditOnly?: boolean;
  isLoading?: boolean;
  displayContentOnly?: boolean;
  documentTypeOptions: Option[];
}

function TemplateForm({
  handleCancel,
  allowContentEditOnly,
  displayContentOnly,
  isLoading,
  documentTypeOptions,
}: Props) {
  const [cancelProcessModalVisible, setCancelProcessModalVisible] = useState<boolean>(false);
  const { values, errors, resetForm } = useFormikContext<
    EditTemplateFormValues | NewTemplateFormValues
  >();

  const onFileInputChange = (form: any, event: any, index: number) => {
    const file = event.target.files[0];
    form.setFieldValue(`attachments[${index}].file`, file);
  };

  function cancelProcess() {
    resetForm();
    setCancelProcessModalVisible(false);
    handleCancel();
  }

  function getSelectedTypeOption(): Option[] {
    return [documentTypeOptions.find((option) => option.key === values.documentType) as Option];
  }

  return (
    <>
      <Form>
        {!displayContentOnly && (
          <>
            <div className="w-full md:!max-w-[500px] max-w-full flex flex-col gap-6">
              <InputField label="Nazwa szablonu" name="name" isReadOnly={allowContentEditOnly} />
              <SelectField
                label="Rodzaj dokumentu"
                name="documentType"
                options={allowContentEditOnly ? getSelectedTypeOption() : documentTypeOptions}
              />
              <InputField label="Opis" name="description" isReadOnly={allowContentEditOnly} />

              <div>
                <FieldArray name="attachments">
                  {(helpers) => (
                    <>
                      <div className="flex flex-col">
                        <p>Załączniki</p>
                        {!allowContentEditOnly && (
                          <Button size="sm" onPress={() => helpers.push(0)}>
                            Dodaj załącznik
                          </Button>
                        )}
                      </div>
                      <div className="flex flex-col">
                        {values?.attachments?.map((attachment, index) => (
                          <div
                            className="flex flex-col my-2"
                            key={attachment?.uploadedFileId || index}>
                            <div className="flex">
                              <Field name={`attachments[${index}].file`}>
                                {({ form }: any) =>
                                  values?.attachments?.[index]?.file instanceof File ? (
                                    <div className="w-full flex items-center">
                                      <i className="bi bi-paperclip cursor-pointer mr-2" />
                                      <p>{values?.attachments?.[index]?.file?.name}</p>
                                    </div>
                                  ) : (
                                    <input
                                      accept=".pdf"
                                      readOnly={allowContentEditOnly}
                                      type="file"
                                      className="w-full"
                                      name={`attachments[${index}].file`}
                                      onChange={(event) => onFileInputChange(form, event, index)}
                                    />
                                  )
                                }
                              </Field>
                              {!allowContentEditOnly && (
                                <Button size="sm" onPress={() => helpers.remove(index)}>
                                  <i className="bi bi-trash cursor-pointer" />
                                </Button>
                              )}
                            </div>

                            {errors?.attachments?.[index] && (
                              <p className="text-xs font-normal text-red-700">
                                {errors?.attachments?.[index]}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col gap-2 mt-4">
          {!displayContentOnly && <Text size="md">Treść szablonu</Text>}
          <CKEditorField name="content" />
        </div>

        <div className="flex justify-end gap-4 mt-4 pb-2">
          <Button
            size="sm"
            variant="outline-primary"
            onPress={() => setCancelProcessModalVisible(true)}>
            <Text weight="semibold" className="flex gap-2 items-center w-full">
              Anuluj
            </Text>
          </Button>
          <Button
            isDisabled={isLoading || !!Object.entries(errors).length}
            size="sm"
            variant="outline-primary"
            type="submit"
            className="flex gap-2">
            {isLoading && <Loader className="h-4 w-4" />}
            <Text weight="semibold" className="flex gap-2 items-center w-full">
              Zapisz
            </Text>
          </Button>
        </div>
      </Form>

      {cancelProcessModalVisible && (
        <ConfirmationModal
          confirmMessage="Czy na pewno chcesz porzucić wprowadzone zmiany?"
          mutate={cancelProcess}
          onCancel={() => setCancelProcessModalVisible(false)}
          confirmLabel="Tak"
          cancelLabel="Nie"
        />
      )}
    </>
  );
}

export default TemplateForm;
