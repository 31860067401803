import { Modal } from '@profitowi/component-library';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { createPdfDocumentFormInitialValues } from './CreatePdfDocumentFormData/createPdfDocumentFormInitialValues';
import {
  CreatePdfDocumentFormValues,
  createPdfDocumentFormValidationSchemaStep1,
  createPdfDocumentFormValidationSchemaStep2,
  createPdfDocumentFormValidationSchemaStep3,
  createPdfDocumentFormValidationSchemaStep4,
} from './CreatePdfDocumentFormData/createPdfDocumentFormValidationSchema';
import InitialDataModal from './Modals/CreatePdfDocumentSteps/Step_1/InitialDataModal';
import RecipientsModal from './Modals/CreatePdfDocumentSteps/Step_2/RecipientsModal';
import AttachmentsModal from './Modals/CreatePdfDocumentSteps/Step_3/AttachmentsModal';
import SignatureQueueModal from './Modals/CreatePdfDocumentSteps/Step_4/SignatureQueueModal';

export enum CreatePdfDocumentFormSteps {
  INITIAL_DATA = 0,
  RECIPIENTS = 1,
  ATTACHMENTS = 2,
  SIGNATURE_QUEUE = 3,
}

function CreatePdfDocumentFormWrapper() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isAbandonModalVisible, setIsAbandonModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const currentPdfDocumentValidationSchema = useMemo(() => {
    if (currentStep === CreatePdfDocumentFormSteps.INITIAL_DATA)
      return createPdfDocumentFormValidationSchemaStep1;
    if (currentStep === CreatePdfDocumentFormSteps.RECIPIENTS)
      return createPdfDocumentFormValidationSchemaStep2;
    if (currentStep === CreatePdfDocumentFormSteps.ATTACHMENTS)
      return createPdfDocumentFormValidationSchemaStep3;
    if (currentStep === CreatePdfDocumentFormSteps.SIGNATURE_QUEUE)
      return createPdfDocumentFormValidationSchemaStep4;
  }, [currentStep]);

  function takeStepAction() {
    if (currentStep === CreatePdfDocumentFormSteps.INITIAL_DATA) {
      return setCurrentStep(CreatePdfDocumentFormSteps.RECIPIENTS);
    }
    if (currentStep === CreatePdfDocumentFormSteps.RECIPIENTS) {
      return setCurrentStep(CreatePdfDocumentFormSteps.ATTACHMENTS);
    }
    if (currentStep === CreatePdfDocumentFormSteps.ATTACHMENTS) {
      return setCurrentStep(CreatePdfDocumentFormSteps.SIGNATURE_QUEUE);
    }
  }

  function handleSubmit(values: CreatePdfDocumentFormValues) {
    takeStepAction();
  }

  function onReset(resetForm: Function) {
    resetForm();
    navigate('/documents');
  }

  function onCancelAbandon(validateForm: () => void) {
    setIsAbandonModalVisible(false);
    validateForm();
  }

  return (
    <Modal
      visible
      size={currentStep === CreatePdfDocumentFormSteps.SIGNATURE_QUEUE ? '3xl' : '2xl'}>
      <Formik
        initialValues={createPdfDocumentFormInitialValues}
        validationSchema={currentPdfDocumentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ resetForm, validateForm }) => (
          <>
            {currentStep === CreatePdfDocumentFormSteps.INITIAL_DATA && (
              <InitialDataModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreatePdfDocumentFormSteps.RECIPIENTS && (
              <RecipientsModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreatePdfDocumentFormSteps.ATTACHMENTS && (
              <AttachmentsModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreatePdfDocumentFormSteps.SIGNATURE_QUEUE && (
              <SignatureQueueModal
                onSuccess={() => onReset(resetForm)}
                onReset={() => setIsAbandonModalVisible(true)}
              />
            )}

            {isAbandonModalVisible && (
              <ConfirmationModal
                confirmMessage="Czy na pewno chcesz porzucić wprowadzone zmiany?"
                mutate={() => onReset(resetForm)}
                onCancel={() => onCancelAbandon(validateForm)}
                confirmLabel="Tak"
                cancelLabel="Nie"
              />
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default CreatePdfDocumentFormWrapper;
