import { Button } from '@profitowi/component-library';
import { parse } from 'date-fns';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { PAYMENT_STATUS_TYPES } from 'constants/paymentStatusTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { Installment } from 'types/advancePayment';
import { DictionaryTypes } from 'types/dictionary';
import { formatBackendDateWithoutTime } from 'utils/date';

export const createColumn = (
  editInstallment: (payment: Installment) => void,
  dictionaries?: DictionaryTypes
): Column<Installment>[] => {
  return [
    {
      id: 'id',
      accessor: undefined,
      Cell: ({ row: { id } }: Cell<Installment>) => Number.parseInt(id) + 1,
    },
    {
      Header: 'Kwota brutto',
      accessor: (row) => <div className="text-right">{row?.grossValue}</div>,
    },
    {
      Header: 'Data płatności',
      accessor: 'paymentDate',
      sortType: (a, b) =>
        parse(a.values.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf() -
        parse(b.values.paymentDate, 'dd-MM-yyyy HH:mm:ss', new Date()).valueOf(),
      Cell: ({
        row: {
          original: { paymentDate },
        },
      }: Cell<Installment>) => formatBackendDateWithoutTime(paymentDate),
    },
    {
      Header: 'Status',
      accessor: (row: Installment) => {
        if (dictionaries) {
          const value = findDictionaryByKey(
            row.paymentStatus,
            dictionaries[DICTIONARY_TYPES.PAYMENT_STATUS]
          );

          if (value) return value.value;
        }
        return row.paymentStatus;
      },
    },
    {
      id: 'operations',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<Installment>) => {
        if (
          original.paymentStatus !== PAYMENT_STATUS_TYPES.TO_REPAY &&
          original.paymentStatus !== PAYMENT_STATUS_TYPES.TO_PAY
        ) {
          return null;
        }
        return (
          <div className="flex justify-end">
            <Button variant="light" onPress={() => editInstallment(original)}>
              <i className="bi bi-three-dots-vertical cursor-pointer" />
            </Button>
          </div>
        );
      },
    },
  ];
};
