import {
  Alert,
  Button,
  DatePickerField,
  InputField,
  MultiSelectField,
  Option,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { Form, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { DICTIONARY_TYPES } from '../../../../../../constants/dictionaryTypes';
import useDictionaryContext from '../../../../../../hooks/useDictionaryContext';
import { FormValues, TemplateStatus } from '../TemplateCreatorList';

const templateStatusOptions: Option[] = [
  { key: 'ACTIVE', value: 'Aktywne szablony' },
  { key: 'ARCHIVED', value: 'Zarchiwizowane szablony' },
];

interface Props {
  handleReset: (setFieldValue: (fieldName: string, fieldValue: string) => void) => void;
  createdAtError?: string;
  lastModifiedAtError?: string;
  handleTemplateStatusChange: (templateStatus: boolean) => void;
}

function TemplateCreatorListFilters({
  handleReset,
  createdAtError,
  lastModifiedAtError,
  handleTemplateStatusChange,
}: Props) {
  const { activeDictionaries } = useDictionaryContext();
  const { setFieldValue, values } = useFormikContext<FormValues>();

  useEffect(() => {
    handleTemplateStatusChange(values.templateStatus === TemplateStatus.ARCHIVED);
  }, [values.templateStatus]);

  return (
    <>
      <Form className="space-y-4 max-w-full w-96">
        <SelectField name="templateStatus" options={templateStatusOptions} />
        <InputField label="Szablon" name="name" />
        <MultiSelectField
          name="documentType"
          label="Rodzaj dokumentu"
          options={activeDictionaries ? activeDictionaries?.[DICTIONARY_TYPES.DOCUMENT_TYPE] : []}
        />
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <DatePickerField
              dateFormat={'dd-MM-yyyy'}
              label="Data utworzenia od"
              name="createdAfter"
            />
            <DatePickerField
              dateFormat={'dd-MM-yyyy'}
              label="Data utworzenia do"
              name="createdBefore"
            />
          </div>
          {createdAtError && <Alert type={'error'}>{createdAtError}</Alert>}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <DatePickerField
              dateFormat={'dd-MM-yyyy'}
              label="Ostatnia modyfikacja od"
              name="lastModifiedAfter"
            />
            <DatePickerField
              dateFormat={'dd-MM-yyyy'}
              label="Ostatnia modyfikacja do"
              name="lastModifiedBefore"
            />
          </div>
          {lastModifiedAtError && <Alert type={'error'}>{lastModifiedAtError}</Alert>}
        </div>
        <div className="flex gap-4">
          <Button size="sm" type="submit">
            <Text weight="semibold" className="flex gap-2 items-center w-full">
              Szukaj
            </Text>
          </Button>
          <Button
            size="sm"
            onPress={() => {
              handleReset(setFieldValue);
            }}>
            <Text weight="semibold" className="flex gap-2 items-center w-full">
              Wyczyść filtry
            </Text>
          </Button>
        </div>
      </Form>
    </>
  );
}

export default TemplateCreatorListFilters;
