import { Button, Header, Icon, Text } from '@profitowi/component-library';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import { CreatePdfDocumentFormValuesStep3 } from 'pages/Documents/CreateDocumentsFromPdf/CreatePdfDocumentFormData/createPdfDocumentFormValidationSchema';
import { useEffect } from 'react';

interface Props {
  onReset: () => void;
}

const AttachmentsModal = ({ onReset }: Props) => {
  const { values, errors, setErrors } = useFormikContext<CreatePdfDocumentFormValuesStep3>();

  useEffect(() => {
    setErrors({});
  }, []);

  const onFileInputChange = (form: any, event: any, index: number) => {
    const file = event.target.files[0];
    form.setFieldValue(`attachments[${index}].file`, file);
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <Header as="h1" size="xl" weight="semibold">
          Utwórz dokument pdf
        </Header>
        <Text size="lg">Krok 3 - załączniki</Text>
      </div>

      <div>
        <Form>
          <div className="my-4 flex flex-col gap-2">
            <div>
              <FieldArray name="attachments">
                {(helpers) => (
                  <>
                    <div className="flex justify-between">
                      <Text size="lg">Załączniki do dokumentu</Text>

                      <Button size="sm" onPress={() => helpers.push(0)}>
                        <Text weight="semibold" className="flex gap-2 items-center w-full">
                          <Icon custom={false} name="plus-lg" />
                          Dodaj nowy
                        </Text>
                      </Button>
                    </div>
                    <div className="flex flex-col">
                      {values?.attachments?.map((attachment: any, index: number) => (
                        <div
                          className="flex flex-col my-2"
                          key={attachment?.uploadedFileId || index}>
                          <div className="flex">
                            <Field name={`attachments[${index}].file`}>
                              {({ form }: any) =>
                                values?.attachments?.[index]?.file instanceof File ? (
                                  <div className="w-full flex items-center">
                                    <i className="bi bi-paperclip cursor-pointer mr-2" />
                                    <Text>{values?.attachments?.[index]?.file?.name}</Text>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    className="w-full"
                                    name={`attachments[${index}].file`}
                                    onChange={(event) => onFileInputChange(form, event, index)}
                                  />
                                )
                              }
                            </Field>
                            <Button size="sm" onPress={() => helpers.remove(index)}>
                              <i className="bi bi-trash cursor-pointer" />
                            </Button>
                          </div>
                          {errors?.attachments?.[index] && (
                            <p className="text-xs font-normal text-red-700">
                              {errors?.attachments?.[index]}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <Button className="flex space-x-2" onPress={onReset} variant="outline-primary">
              <Icon name="x-lg" />
              <Text>Anuluj</Text>
            </Button>
            <Button
              className="flex space-x-2"
              type="submit"
              isDisabled={!!Object.entries(errors).length}
              variant="primary">
              <Icon name="arrow-right" />
              <Text>Dalej</Text>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AttachmentsModal;
