import { Modal } from '@profitowi/component-library';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { createTemplateDocumentFormInitialValues } from './CreateTemplateDocumentFormData/createTemplateDocumentFormInitialValues';
import {
  CreateTemplateDocumentFormValues,
  createTemplateDocumentFormValidationSchemaStep1,
  createTemplateDocumentFormValidationSchemaStep2,
  createTemplateDocumentFormValidationSchemaStep3,
  createTemplateDocumentFormValidationSchemaStep4,
} from './CreateTemplateDocumentFormData/createTemplateDocumentFormValidationSchema';
import InitialDataModal from './Modals/CreateTemplateDocumentSteps/Step_1/InitialDataModal';
import RecipientsModal from './Modals/CreateTemplateDocumentSteps/Step_2/RecipientsModal';
import AttachmentsModal from './Modals/CreateTemplateDocumentSteps/Step_3/AttachmentsModal';
import SignatureQueueModal from './Modals/CreateTemplateDocumentSteps/Step_4/SignatureQueueModal';
import EditDocumentTemplateModal from './Modals/EditTemplateModal/EditDocumentTemplateModal';

export enum CreateTemplateDocumentFormSteps {
  INITIAL_DATA = 0,
  RECIPIENTS = 1,
  ATTACHMENTS = 2,
  SIGNATURE_QUEUE = 3,
  EDIT_DOC_TEMPLATE = 4,
}

function CreateTemplateDocumentFormWrapper() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isAbandonModalVisible, setIsAbandonModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const currentValidationSchema = useMemo(() => {
    if (currentStep === CreateTemplateDocumentFormSteps.INITIAL_DATA)
      return createTemplateDocumentFormValidationSchemaStep1;
    if (currentStep === CreateTemplateDocumentFormSteps.RECIPIENTS)
      return createTemplateDocumentFormValidationSchemaStep2;
    if (currentStep === CreateTemplateDocumentFormSteps.ATTACHMENTS)
      return createTemplateDocumentFormValidationSchemaStep3;
    if (currentStep === CreateTemplateDocumentFormSteps.SIGNATURE_QUEUE)
      return createTemplateDocumentFormValidationSchemaStep4;
  }, [currentStep]);

  function takeStepAction() {
    if (currentStep === CreateTemplateDocumentFormSteps.INITIAL_DATA) {
      return setCurrentStep(CreateTemplateDocumentFormSteps.RECIPIENTS);
    }
    if (currentStep === CreateTemplateDocumentFormSteps.RECIPIENTS) {
      return setCurrentStep(CreateTemplateDocumentFormSteps.ATTACHMENTS);
    }
    if (currentStep === CreateTemplateDocumentFormSteps.ATTACHMENTS) {
      return setCurrentStep(CreateTemplateDocumentFormSteps.SIGNATURE_QUEUE);
    }
  }

  function handleSubmit(values: CreateTemplateDocumentFormValues) {
    takeStepAction();
  }

  function onReset(resetForm: Function) {
    resetForm();
    navigate('/documents');
  }

  function onCancelAbandon(validateForm: () => void) {
    setIsAbandonModalVisible(false);
    validateForm();
  }

  return (
    <Modal
      visible
      size={currentStep === CreateTemplateDocumentFormSteps.SIGNATURE_QUEUE ? '3xl' : '2xl'}>
      <Formik
        initialValues={createTemplateDocumentFormInitialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ resetForm, validateForm }) => (
          <>
            {currentStep === CreateTemplateDocumentFormSteps.INITIAL_DATA && (
              <InitialDataModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreateTemplateDocumentFormSteps.RECIPIENTS && (
              <RecipientsModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreateTemplateDocumentFormSteps.ATTACHMENTS && (
              <AttachmentsModal onReset={() => setIsAbandonModalVisible(true)} />
            )}
            {currentStep === CreateTemplateDocumentFormSteps.SIGNATURE_QUEUE && (
              <SignatureQueueModal
                onReset={() => setIsAbandonModalVisible(true)}
                onSuccess={() => onReset(resetForm)}
                onNavigateToEditTemplate={() =>
                  setCurrentStep(CreateTemplateDocumentFormSteps.EDIT_DOC_TEMPLATE)
                }
              />
            )}

            {currentStep === CreateTemplateDocumentFormSteps.EDIT_DOC_TEMPLATE && (
              <EditDocumentTemplateModal
                onNavigate={() => setCurrentStep(CreateTemplateDocumentFormSteps.SIGNATURE_QUEUE)}
              />
            )}

            {isAbandonModalVisible && (
              <ConfirmationModal
                confirmMessage="Czy na pewno chcesz porzucić wprowadzone zmiany?"
                mutate={() => onReset(resetForm)}
                onCancel={() => onCancelAbandon(validateForm)}
                confirmLabel="Tak"
                cancelLabel="Nie"
              />
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateTemplateDocumentFormWrapper;
