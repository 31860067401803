import {
  DocumentGenerationType,
  DocumentSignatureStatus,
  RecipientType,
} from '../../../../types/documents';
import { SpecialDocumentType } from './../../../../types/agent';
import { CreatePdfDocumentFormValues } from './createPdfDocumentFormValidationSchema';

export const createPdfDocumentFormInitialValues: CreatePdfDocumentFormValues = {
  documentName: '',
  documentBase: undefined as any,
  members: [],
  documentType: SpecialDocumentType.DOCUMENT_PDF,
  proxies: [],
  recipientContactMethod: RecipientType.SINGLE_RECIPIENT,
  user: '',
  party: '',
  schema: '',
  positions: [],
  csvFile: undefined as any,
  attachments: [],
  signature: DocumentSignatureStatus.SIGNED_DOCUMENT,
  queue: [],
  assignments: [],
  customContent: undefined as any,
  documentGenerationType: DocumentGenerationType.PDF_BASED,
  attachmentsFromTemplates: [],
};
