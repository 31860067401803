import { Button, Icon } from '@profitowi/component-library';
import React from 'react';
import { Cell, Column, Row } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { SpecialDocumentType } from 'types/agent';
import { DictionaryTypes } from 'types/dictionary';
import { DocumentListItem, SigningStatus, UserDocumentRole } from 'types/documents';

interface Props {
  isActiveDocumentsView: boolean;
  dictionaries?: DictionaryTypes;
  handleSend: (documentId: number) => void;
  sendReminder: (documentId: number) => void;
  editDocument: (documentId: number) => void;
  historyDocument: (documentId: number, documentName: string) => void;
  signDocuments: (documentId: number) => void;
  restoreDocument: (documentId: number) => void;
  canSendDocument: (documentId: DocumentListItem) => boolean;
  canRemindDocument: (documentId: DocumentListItem) => boolean;
  canSignDocument: (documentId: DocumentListItem) => boolean;
  canEditDocument: (documentId: DocumentListItem) => boolean;
  canRestoreDocuments: (documentId: DocumentListItem) => boolean;
  downloadDocuments: (documentId: number) => void;
}

export const createColumn = ({
  isActiveDocumentsView,
  dictionaries,
  handleSend,
  sendReminder,
  editDocument,
  signDocuments,
  restoreDocument,
  canSendDocument,
  canRemindDocument,
  canSignDocument,
  canEditDocument,
  canRestoreDocuments,
  historyDocument,
  downloadDocuments,
}: Props): Column<DocumentListItem>[] => {
  function getUserStatusColor(userStatus: SigningStatus) {
    switch (userStatus) {
      case SigningStatus.PENDING:
        return 'text-icon-orange';
      case SigningStatus.RECEIVED:
      case SigningStatus.OPENED:
        return 'text-light';
      case SigningStatus.SENT:
      case SigningStatus.READ:
      case SigningStatus.SIGNED:
        return 'text-primary';
    }
  }

  function getUserStatusIcon(userStatus: SigningStatus) {
    switch (userStatus) {
      case SigningStatus.PENDING:
        return 'clock';
      case SigningStatus.OPENED:
        return 'exclamation-circle-fill';
      case SigningStatus.RECEIVED:
        return 'exclamation-circle';
      case SigningStatus.SENT:
      case SigningStatus.READ:
      case SigningStatus.SIGNED:
        return 'check-circle-fill';
    }
  }

  return [
    {
      id: 'expander',
      Cell: ({ row }: Cell<DocumentListItem>) =>
        row.original.members.length > 1 ? (
          <div {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
          </div>
        ) : null,
    },
    {
      accessor: 'id',
      Header: 'Id',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'documentStatus',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        if (dictionaries && original?.documentStatus) {
          const value = findDictionaryByKey(
            original.documentStatus,
            dictionaries[DICTIONARY_TYPES.DOCUMENT_STATUS]
          );

          if (!!value) {
            return <div className="whitespace-nowrap">{value.value}</div>;
          }
        }
        return <div className="whitespace-nowrap">{original.documentStatus}</div>;
      },
      disableSortBy: true,
    },
    {
      accessor: 'currentLoggedUserStatus',
      Header: 'Mój Status',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        if (original.currentLoggedUserStatus && dictionaries) {
          const value = findDictionaryByKey(
            original.currentLoggedUserStatus,
            dictionaries[DICTIONARY_TYPES.SIGNING_STATUS]
          );

          if (value) return <div className="whitespace-nowrap">{value.value}</div>;
        }
        return <div className="whitespace-nowrap">{original.currentLoggedUserStatus}</div>;
      },
      disableSortBy: true,
    },
    {
      accessor: 'documentName',
      Header: 'Nazwa dokumentu',
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        return (
          <div
            title={original.documentName}
            className="whitespace-nowrap truncate overflow-hidden w-44">
            {original.documentName}
          </div>
        );
      },
    },
    {
      accessor: 'documentTypeFromDictionary',
      Header: 'Rodzaj Dokumentu',
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        if (dictionaries && original?.documentTypeFromDictionary) {
          const value =
            findDictionaryByKey(
              original.documentTypeFromDictionary,
              dictionaries[DICTIONARY_TYPES.DOCUMENT_TYPE]
            ) ||
            findDictionaryByKey(
              original.documentTypeFromDictionary,
              dictionaries[DICTIONARY_TYPES.SPECIAL_DOCUMENT_TYPE]
            );

          if (!!value) {
            return <div className="whitespace-nowrap">{value.value}</div>;
          }
        }
        return <div className="whitespace-nowrap">{original.documentTypeFromDictionary}</div>;
      },
    },
    {
      id: 'firstMember',
      Header: 'Pełnomocnik',
      accessor: (row) => {
        const proxy = row?.members.find(
          (member) =>
            member.role !== UserDocumentRole.RECEIVER && member.role !== UserDocumentRole.PARTY
        );
        if (proxy) {
          let title = '';

          if (dictionaries && proxy.userStatus) {
            title =
              findDictionaryByKey(proxy.userStatus, dictionaries[DICTIONARY_TYPES.SIGNING_STATUS])
                ?.value || '';
          }

          return (
            <div className="whitespace-nowrap flex gap-2" title={title}>
              {proxy.fullName}
              <Icon
                name={getUserStatusIcon(proxy.userStatus)}
                className={`${getUserStatusColor(proxy.userStatus)} rounded-full w-4 h-4`}
              />
            </div>
          );
        }
      },
      disableSortBy: true,
    },
    {
      id: 'recipientCode',
      Header: 'Kod Odbiorcy',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => (
        <div className="whitespace-nowrap">
          {original?.members.find((member) => member.role === UserDocumentRole.RECEIVER)
            ?.agentCode || ''}
        </div>
      ),
      disableSortBy: true,
    },
    {
      id: 'recipient',
      Header: 'Odbiorca',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        const proxy = original?.members.find((member) => member.role === UserDocumentRole.RECEIVER);
        if (proxy) {
          let title = '';

          if (dictionaries && proxy.userStatus) {
            title =
              findDictionaryByKey(proxy.userStatus, dictionaries[DICTIONARY_TYPES.SIGNING_STATUS])
                ?.value || '';
          }

          return (
            <div className="whitespace-nowrap flex gap-2" title={title}>
              {proxy.fullName}
              <Icon
                name={getUserStatusIcon(proxy.userStatus)}
                className={`${getUserStatusColor(proxy.userStatus)} rounded-full w-4 h-4`}
              />
            </div>
          );
        } else return <></>;
      },
      disableSortBy: true,
    },
    {
      id: 'recipientsCount',
      Header: 'Liczba Użytkowników',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => {
        if (!original) return 0;

        if (original.documentTypeFromDictionary === SpecialDocumentType.MIGRATION) {
          return ' ';
        }

        return original.members?.length || 0;
      },
      disableSortBy: true,
    },
    {
      accessor: 'createdAt',
      Header: 'Data Utworzenia',
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => (
        <div className="whitespace-nowrap">{original?.createdAt}</div>
      ),
    },
    {
      Header: 'Utworzono Przez',
      accessor: (row) => <div className="whitespace-nowrap">{row?.createdBy}</div>,
      disableSortBy: true,
    },
    {
      accessor: 'comment',
      Header: 'Komentarz',
      disableSortBy: true,
      Cell: ({ row: { original } }: { row: Row<DocumentListItem> }) => (
        <div title={original?.comment} className="whitespace-nowrap truncate overflow-hidden w-40">
          {original?.comment}
        </div>
      ),
    },
    {
      id: 'operations',
      Header: 'Akcje',
      Cell: ({ row: { original } }) => {
        return (
          <div className="flex justify-start items-center">
            <div title="Pobierz">
              <Button variant="light" onPress={() => downloadDocuments(original.id)}>
                <Icon name="download" />
              </Button>
            </div>

            <div title="Historia">
              <Button
                variant="light"
                onPress={() => historyDocument(original.id, original.documentName)}>
                <Icon name="clock-history" />
              </Button>
            </div>

            {!isActiveDocumentsView && (
              <>
                {canRestoreDocuments(original) && (
                  <div title="Przywróć">
                    <Button variant="light" onPress={() => restoreDocument(original.id)}>
                      <Icon name="arrow-counterclockwise" />
                    </Button>
                  </div>
                )}
              </>
            )}
            {isActiveDocumentsView && (
              <>
                {canEditDocument(original) && (
                  <div title="Edytuj">
                    <Button variant="light" onPress={() => editDocument(original.id)}>
                      <Icon name="pencil-fill" />
                    </Button>
                  </div>
                )}
                {canSendDocument(original) && (
                  <div title="Wyślij">
                    <Button variant="light" onPress={() => handleSend(original.id)}>
                      <Icon name="send-fill" />
                    </Button>
                  </div>
                )}
                {canRemindDocument(original) && (
                  <div title="Wyślij przypomnienie">
                    <Button variant="light" onPress={() => sendReminder(original.id)}>
                      <Icon name="bell-fill" />
                    </Button>
                  </div>
                )}
                {canSignDocument(original) && (
                  <div title="Podpisz">
                    <Button variant="light" onPress={() => signDocuments(original.id)}>
                      <Icon name="pen-fill" />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
  ];
};
