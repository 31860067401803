import { format } from 'date-fns';
import { Cell, Column } from 'react-table';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import { findDictionaryByKey } from 'helpers/dictionaries';
import { DictionaryTypes } from 'types/dictionary';
import { DocumentListItemMember } from 'types/documents';

export const createColumn = ({
  dictionaries,
}: {
  dictionaries?: DictionaryTypes;
}): Column<DocumentListItemMember>[] => {
  return [
    {
      Header: 'Rola',
      accessor: 'role',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { role },
        },
      }: Cell<DocumentListItemMember>) => {
        if (role && dictionaries) {
          const value = findDictionaryByKey(
            role,
            dictionaries[DICTIONARY_TYPES.USER_DOCUMENT_ROLE]
          );

          if (value) return <div className="narrow-column"> {value.value} </div>;
        }
        return <div className="narrow-column">{role}</div>;
      },
    },
    {
      accessor: 'agentCode',
      Header: 'Kod',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { agentCode },
        },
      }: Cell<DocumentListItemMember>) => {
        return <div className="narrow-column">{agentCode}</div>;
      },
    },
    {
      accessor: 'fullName',
      Header: 'Imię i nazwisko',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { fullName },
        },
      }: Cell<DocumentListItemMember>) => {
        return <div className="wider-column whitespace-nowrap truncate">{fullName}</div>;
      },
    },
    {
      Header: 'Status',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { userStatus },
        },
      }: Cell<DocumentListItemMember>) => {
        if (userStatus && dictionaries) {
          const value = findDictionaryByKey(
            userStatus,
            dictionaries[DICTIONARY_TYPES.SIGNING_STATUS]
          );

          if (value) return <div className="narrow-column"> {value.value} </div>;
        }
        return <div className="narrow-column">{userStatus}</div>;
      },
    },
    {
      accessor: 'signatureDate',
      Header: 'Data podpisu',
      Cell: ({
        row: {
          original: { signatureDate },
        },
      }: Cell<DocumentListItemMember>) => {
        return !!signatureDate ? format(new Date(signatureDate), 'dd-MM-yyyy HH:mm:ss') : '';
      },
      disableSortBy: true,
    },
  ];
};
