import { Box, Loader, Table } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { endOfDay, format, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Row, SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DOCUMENTS_LIST } from 'constants/queries/documentsList';
import useDictionaryContext from 'hooks/useDictionaryContext';
import useDocumentContext from 'hooks/useDocumentContext';
import { usePagination } from 'hooks/usePagination';
import { downloadDocuments, getDocumentsList } from 'services/documents';
import { SpecialDocumentType } from 'types/agent';
import {
  DocumentListItem,
  DocumentStatus,
  GetDocumentsRequest,
  SigningStatus,
} from 'types/documents';
import { Page } from 'types/request';
import { Roles } from 'types/user';
import { saveFile } from 'utils/file';
import { decodeUriSortParams } from 'utils/table';

import useAgentRoleContext from '../../../hooks/useAgentRoleContext';
import { createColumn } from './columns';
import ActionButtons from './components/ActionButtons/ActionButtons';
import DocumentFiltersForm from './components/DocumentFiltersForm/DocumentFiltersForm';
import { documentFiltersFormInitialValues } from './components/DocumentFiltersForm/initialValues';
import {
  DocumentFiltersFormValues,
  documentFiltersFormValidationSchema,
} from './components/DocumentFiltersForm/validationSchema';
import DocumentRecipientsDetails from './components/DocumentRecipientsDetails/DocumentRecipientsDetails';
import ArchiveDocumentsModal from './components/modals/ArchiveDocumentsModal';
import DeleteDocumentsModal from './components/modals/DeleteDocumentsModal';
import EditDocumentModal from './components/modals/EditDocumentModal';
import GenerateReportDocumentsModal from './components/modals/GenerateReportDocumentsModal';
import HistoryDocumentsModal from './components/modals/HistoryDocumentsModal/HistoryDocumentsModal';
import RestoreDocumentsModal from './components/modals/RestoreDocumentsModal';
import SendDocumentsModal from './components/modals/SendDocumentsModal';
import SendReminderDocumentsModal from './components/modals/SendReminderDocumentsModal';
import SignDocumentsModal from './components/modals/SignDocumentsModal';

enum DocumentActions {
  DELETE = 'delete',
  SEND = 'send',
  RESTORE = 'restore',
  ARCHIVE = 'archive',
  GENERATE_REPORT = 'generateReport',
  SEND_REMINDER = 'sendReminder',
  DOWNLOAD = 'download',
  SIGN = 'sign',
  EDIT = 'edit',
  HISTORY = 'history',
}

function DocumentsList({ isActiveDocumentsView }: { isActiveDocumentsView: boolean }) {
  const tableRef = useRef<any>(null);
  const { refetchDocumentCount } = useDocumentContext();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [payload, setPayload] = useState<GetDocumentsRequest>();
  const { activeDictionaries } = useDictionaryContext();
  const { hasRole } = useAgentRoleContext();
  const hasDocumentManagementRole = hasRole(Roles.DOCUMENTS_MANAGEMENT_TEMPLATE);
  const navigate = useNavigate();

  const handleSelectionChange = useCallback(
    (rows: DocumentListItem[]) => {
      selectedRows?.length !== rows?.length && setSelectedRows(rows.map(({ id }) => id));
    },
    [selectedRows?.length]
  );

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);
  const pagination = usePagination(10);

  const { data, refetch, isError, isLoading, isFetching, isSuccess, error } = useQuery<
    Page<DocumentListItem>,
    AxiosError
  >(
    [DOCUMENTS_LIST.DOCUMENTS_LIST, pagination.currentPage, pagination.perPage, sortBy, payload],
    () =>
      getDocumentsList(
        pagination.currentPage,
        pagination.perPage,
        {
          ...payload,
          dateFrom: payload?.dateFrom
            ? format(startOfDay(new Date(payload.dateFrom)), 'dd-MM-yyyy HH:mm:ss')
            : (undefined as unknown as Date),
          dateTo: payload?.dateTo
            ? format(endOfDay(new Date(payload.dateTo)), 'dd-MM-yyyy HH:mm:ss')
            : undefined,
          isArchived: !isActiveDocumentsView,
        },
        decodeUriSortParams(sortBy)
      ),
    {
      keepPreviousData: true,
      onSettled: () => {
        tableRef?.current?.resetSelectedRows?.();
      },
    }
  );

  const {
    isError: isErrorDownloadDocuments,
    isLoading: isLoadingDownloadDocuments,
    mutate: mutateDownloadDocuments,
    error: errorDownloadDocuments,
  } = useMutation<AxiosResponse & { isPdfFile: boolean }, AxiosError, number | undefined>(
    (documentId) => downloadDocuments(documentId ? [documentId] : selectedRows),
    {
      onSuccess: (response) => {
        const fileName = response.headers['file-name'];
        if (response.isPdfFile) {
          saveFile(response.data, decodeURIComponent(fileName), 'application/pdf');
        } else {
          saveFile(response.data, decodeURIComponent(fileName), 'application/zip');
        }

        refetchDocumentCount();
        refetch();
      },
    }
  );

  useEffect(() => {
    if (pagination.currentPage !== 0) {
      pagination.setPage(0);
    } else {
      refetch();
    }
  }, [isActiveDocumentsView]);

  function onSubmit(values: DocumentFiltersFormValues) {
    pagination.setPage(0);
    setPayload({
      agentCode: !!values.agentCode ? values.agentCode : null,
      recipientName: !!values.recipientName ? values.recipientName : null,
      documentName: !!values.documentName ? values.documentName : null,
      templateId: !!values.templateId ? values.templateId : null,
      documentTypeFromDict: !!values.documentTypeFromDict ? values.documentTypeFromDict : null,
      dateFrom: !!values.dateFrom ? values.dateFrom : null,
      dateTo: !!values.dateTo ? values.dateTo : null,
      isArchived: !!values.isArchived ? values.isArchived : null,
      documentStatus: !!values.documentStatus ? values.documentStatus : null,
      mySigningStatus: !!values.mySigningStatus ? values.mySigningStatus : null,
      createdBy: !!values.createdBy ? values.createdBy : null,
    });
  }

  function editDocument(documentId: number) {
    navigate(DocumentActions.EDIT, { state: { documentId } });
  }

  function historyDocument(documentId: number, documentName: string) {
    navigate(DocumentActions.HISTORY, { state: { documentId, documentName } });
  }

  const handleAction = useCallback(
    (action: string, documentId?: number) => {
      navigate(action, { state: { documentIds: documentId ? [documentId] : selectedRows } });
    },
    [selectedRows, navigate]
  );

  const canDeleteDocument: boolean = useMemo(() => {
    if (!hasDocumentManagementRole || !selectedRows.length) return false;

    if (isActiveDocumentsView) {
      return selectedRows.every((id) => {
        const document = data?.content?.find((doc) => doc.id === id);
        return document?.documentStatus === DocumentStatus.DRAFT;
      });
    } else {
      return selectedRows.every((id) => {
        const document = data?.content?.find((doc) => doc.id === id);
        return document?.documentStatus !== DocumentStatus.COMPLETED;
      });
    }
  }, [selectedRows, hasDocumentManagementRole]);

  const canEditDocument = useCallback(
    (document: DocumentListItem): boolean => {
      if (!hasDocumentManagementRole || document.isArchived) return false;

      return document.documentStatus === DocumentStatus.DRAFT;
    },
    [selectedRows, hasDocumentManagementRole]
  );

  const canSendDocument = useCallback(
    (document?: DocumentListItem): boolean => {
      if (document) {
        return document.documentStatus === DocumentStatus.DRAFT;
      }

      if (!selectedRows.length) return false;

      return selectedRows.every((id) => {
        const document = data?.content?.find((doc) => doc.id === id);
        if (!document || document.isArchived) return false;

        return document.documentStatus === DocumentStatus.DRAFT;
      });
    },
    [selectedRows, hasDocumentManagementRole]
  );

  const canArchiveDocument: boolean = useMemo(() => {
    if (!selectedRows.length) return false;

    return selectedRows.every((id) => {
      const document = data?.content?.find((doc) => doc.id === id);
      if (!document || document.isArchived) return false;

      return (
        document.documentStatus !== DocumentStatus.DRAFT &&
        document.documentStatus !== DocumentStatus.IN_SENDING
      );
    });
  }, [selectedRows, hasDocumentManagementRole]);

  const canRemindDocument = useCallback(
    (document?: DocumentListItem): boolean => {
      if (document) {
        return (
          document.documentStatus === DocumentStatus.IN_PROGRESS ||
          document.documentStatus === DocumentStatus.SENT_COMPLETED
        );
      }

      if (!selectedRows.length) return false;

      return selectedRows.every((id) => {
        const document = data?.content?.find((doc) => doc.id === id);
        if (!document || document.isArchived) return false;

        return (
          document.documentStatus === DocumentStatus.IN_PROGRESS ||
          document.documentStatus === DocumentStatus.SENT_COMPLETED
        );
      });
    },
    [selectedRows, hasDocumentManagementRole]
  );

  const canSignDocument = useCallback(
    (document?: DocumentListItem): boolean => {
      if (document) {
        return (
          document.isArchived === false &&
          document.isSignatureRequired === true &&
          (document.currentLoggedUserStatus === SigningStatus.RECEIVED ||
            document.currentLoggedUserStatus === SigningStatus.OPENED)
        );
      }

      if (!isActiveDocumentsView || !selectedRows.length) return false;

      return selectedRows.every((id) => {
        const document = data?.content?.find((doc) => doc.id === id);
        if (!document || !document.currentLoggedUserStatus) return false;

        return (
          document.isArchived === false &&
          document.isSignatureRequired === true &&
          (document.currentLoggedUserStatus === SigningStatus.RECEIVED ||
            document.currentLoggedUserStatus === SigningStatus.OPENED)
        );
      });
    },
    [selectedRows, hasDocumentManagementRole]
  );

  const canDownloadDocuments = useMemo((): boolean => {
    if (selectedRows.length >= 1) {
      return true;
    }
    return false;
  }, [selectedRows]);

  const canRestoreDocuments = useCallback(
    (document?: DocumentListItem): boolean => {
      if (
        (document && document?.documentTypeFromDictionary !== SpecialDocumentType.MIGRATION) ||
        (selectedRows.length >= 1 &&
          selectedRows.every(
            (row) =>
              data?.content?.find((doc) => doc.id === row)?.documentTypeFromDictionary !==
              SpecialDocumentType.MIGRATION
          ))
      ) {
        return true;
      }
      return false;
    },
    [selectedRows]
  );

  const columns = useMemo(
    () =>
      createColumn({
        isActiveDocumentsView,
        dictionaries: activeDictionaries,
        handleSend: (documentId: number) => handleAction(DocumentActions.SEND, documentId),
        sendReminder: (documentId: number) =>
          handleAction(DocumentActions.SEND_REMINDER, documentId),
        signDocuments: (documentId: number) => handleAction(DocumentActions.SIGN, documentId),
        restoreDocument: (documentId: number) => handleAction(DocumentActions.RESTORE, documentId),
        downloadDocuments: (documentId: number) => mutateDownloadDocuments(documentId),
        editDocument: (documentId: number) => editDocument(documentId),
        historyDocument: (documentId: number, documentName: string) =>
          historyDocument(documentId, documentName),
        canSendDocument: canSendDocument,
        canRemindDocument: canRemindDocument,
        canSignDocument: canSignDocument,
        canEditDocument: canEditDocument,
        canRestoreDocuments: canRestoreDocuments,
      }),
    [activeDictionaries, data]
  );

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Formik
            initialValues={documentFiltersFormInitialValues}
            validationSchema={documentFiltersFormValidationSchema}
            onSubmit={onSubmit}
            onReset={() => {
              pagination.setPage(0);
              setPayload(undefined);
            }}>
            {({}) => <DocumentFiltersForm customClasses="w-96" />}
          </Formik>

          <ActionButtons
            isActiveDocumentsView={isActiveDocumentsView}
            handleDelete={() => handleAction(DocumentActions.DELETE)}
            handleSend={() => handleAction(DocumentActions.SEND)}
            handleArchive={() => handleAction(DocumentActions.ARCHIVE)}
            generateReport={() => handleAction(DocumentActions.GENERATE_REPORT)}
            sendReminder={() => handleAction(DocumentActions.SEND_REMINDER)}
            downloadDocuments={() => mutateDownloadDocuments(undefined)}
            signDocuments={() => handleAction(DocumentActions.SIGN)}
            restoreDocument={() => handleAction(DocumentActions.RESTORE)}
            canDeleteDocument={canDeleteDocument}
            canSendDocument={canSendDocument()}
            canArchiveDocument={canArchiveDocument}
            canRemindDocument={canRemindDocument()}
            canSignDocument={canSignDocument()}
            canDownloadDocuments={canDownloadDocuments}
            canRestoreDocuments={canRestoreDocuments()}
          />
        </div>

        {(isLoadingDownloadDocuments || isLoading) && <Loader />}

        <div className="my-4">
          {(isError || isErrorDownloadDocuments) && (
            <ErrorMessages error={error || errorDownloadDocuments} />
          )}
        </div>

        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              ref={tableRef}
              isSelectable
              isExpandable
              pagination={pagination}
              sortBy={sortBy}
              data={data.content || []}
              columns={columns}
              totalPages={data.totalPages}
              onSortBy={handleSortBy}
              onSelectionChange={handleSelectionChange}
              isLoading={isFetching}
              renderRowSubComponent={({ row }: { row: Row<DocumentListItem> }) => (
                <div className="pl-20">
                  <DocumentRecipientsDetails members={row.original.members} />
                </div>
              )}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route path={DocumentActions.DELETE} element={<DeleteDocumentsModal refetch={refetch} />} />
        <Route path={DocumentActions.SEND} element={<SendDocumentsModal refetch={refetch} />} />
        <Route
          path={DocumentActions.ARCHIVE}
          element={<ArchiveDocumentsModal refetch={refetch} />}
        />
        <Route
          path={DocumentActions.GENERATE_REPORT}
          element={<GenerateReportDocumentsModal refetch={refetch} />}
        />
        <Route
          path={DocumentActions.SEND_REMINDER}
          element={<SendReminderDocumentsModal refetch={refetch} />}
        />
        <Route path={DocumentActions.SIGN} element={<SignDocumentsModal refetch={refetch} />} />
        <Route
          path={DocumentActions.RESTORE}
          element={<RestoreDocumentsModal refetch={refetch} />}
        />
        <Route path={DocumentActions.EDIT} element={<EditDocumentModal refetch={refetch} />} />
        <Route path={DocumentActions.HISTORY} element={<HistoryDocumentsModal />} />
      </Routes>

      <Outlet />
    </>
  );
}

export default DocumentsList;
