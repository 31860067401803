import { InputField, Modal, TextareaField } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { MessageTemplate, TemplateType } from 'types/template';

const validationSchema = Yup.object().shape({
  title: Yup.string(),
  content: Yup.string().required(requiredFieldMessage),
});

type Props = {
  visible: boolean;
  editedItem: MessageTemplate;
  updateTemplateError?: AxiosError | null;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (template: MessageTemplate) => void;
  closeModal: () => void;
};

const DictionaryForm = ({
  visible,
  editedItem,
  updateTemplateError,
  setVisible,
  onSave,
  closeModal,
}: Props) => {
  return (
    <Modal title={'Edytuj szablon'} visible={visible} setVisible={setVisible}>
      <Formik
        initialValues={{
          templateType: !!editedItem.templateType
            ? editedItem.templateType
            : (undefined as unknown as TemplateType),
          title: !!editedItem.title ? editedItem.title : (undefined as unknown as string),
          content: !!editedItem.content ? editedItem.content : (undefined as unknown as string),
        }}
        onSubmit={onSave}
        validateOnMount
        validationSchema={validationSchema}>
        <Form className="space-y-4">
          <InputField name="title" label="Tytuł" />
          <TextareaField rows={10} name="content" label="Treść" />
          <ModalActions onCancel={closeModal} />

          {updateTemplateError && <ErrorMessages error={updateTemplateError} />}
        </Form>
      </Formik>
    </Modal>
  );
};

export default DictionaryForm;
