import {
  DocumentGenerationType,
  DocumentPaperType,
  DocumentSignatureStatus,
  RecipientType,
} from '../../../../types/documents';
import { CreateTemplateDocumentFormValues } from './createTemplateDocumentFormValidationSchema';

export const createTemplateDocumentFormInitialValues: CreateTemplateDocumentFormValues = {
  customContent: undefined as any,
  documentGenerationType: DocumentGenerationType.TEMPLATE_BASED,
  selectedTemplate: undefined,
  documentName: '',
  documentType: undefined as any,
  template: undefined as any,
  paperType: 'CORPORATE',
  documentDate: undefined as any,
  assignments: [],
  proxies: [],
  attachmentsFromTemplates: [],
  recipientContactMethod: RecipientType.SINGLE_RECIPIENT,
  user: '',
  party: '',
  schema: '',
  positions: [],
  csvFile: undefined as any,
  attachments: [],
  signature: DocumentSignatureStatus.SIGNED_DOCUMENT,
  queue: [],
};
